import React from "react"
import * as FadeIn from "components/fadein"

const AboutPeu = () => {
  return (
    <FadeIn.Up>
      <section className="detergentPage__aboutPeu">
        <div className="detergentPage__container">
          <div className="detergentPage__titleContents tx-center">
            <img
              className="detergentPage__titleImg"
              src="https://balukoweb.blob.core.windows.net/images/self-laundry/detergent/peu.svg"
              alt="peu title"
            />
          </div>

          <p className="detergentPage__lead tx-center fw-b">
            peuは洗うに必要な成分のみでつくったシンプルな洗剤です。
            <br className="pc" />
            「洗う」とは「衣類の汚れをしっかり落とし・洗濯後の繊維に不要なものを残さない」こと。
            <br className="pc" />
            不要なものを残さないから、衣類の本来の表情に触れられます。
            <br className="pc" />
            成分には水と汚れになじんで溶けやすい、植物由来の合成界面活性剤と天然の純石けん分を選びました。
            <br className="pc" />
            不要なものを残さない、だからpeuは香料不使用です。
          </p>

          <div className="detergentPage__detailText tx-center">
            <p>
              バルコランドリープレイスでは、自社開発のオリジナル洗剤 peu（ピウ）
              を使用しています。
              <br />
              スタンダードコース、標準コース、羽毛布団コースなどでご利用いただけます。
              <br />
              <span>※各店舗の仕様洗剤は、店舗内の掲示をご確認ください。</span>
            </p>
          </div>
        </div>
      </section>
    </FadeIn.Up>
  )
}
export default AboutPeu
