import React from "react"
import * as FadeIn from "components/fadein"
import ContnetsTitle from "components/contentsTitle"

const Product = () => {
  return (
    <FadeIn.Up>
      <section className="detergentPage__product">
        <div className="detergentPage__containerWrap">
          <div className="detergentPage__container">
            <FadeIn.Up>
              <div className="detergentPage__qualityDetail">
                <ContnetsTitle title="Quality" subTitle="特徴" />
                <div className="detergentPage__detailTop tx-center">
                  <div className="detergentPage__detailTitle">
                    <img
                      src="https://balukoweb.blob.core.windows.net/images/self-laundry/detergent/quality-icon01.svg"
                      alt="quality contents icon"
                    />
                    <p className="fw-b">毎日の洗濯に安心して使えます</p>
                  </div>
                </div>
                <div className="detergentPage__detailBottom">
                  <p className="detergentPage__lead tx-center fw-b">
                    衣類を傷めにくい、中性に近い弱アルカリ性の洗剤です。
                    <br />
                    人体や環境への影響に配慮した成分／使用量で設計しています。
                    <br />
                    香料や着色料を一切使用していません
                  </p>
                </div>

                <div className="detergentPage__detailTop tx-center">
                  <div className="detergentPage__detailTitle">
                    <img
                      src="https://balukoweb.blob.core.windows.net/images/self-laundry/detergent/quality-icon02.svg"
                      alt="quality contents icon"
                    />
                    <p className="fw-b">洗濯物をすっきりと洗いあげます</p>
                  </div>
                </div>
                <div className="detergentPage__detailBottom">
                  <p className="detergentPage__lead tx-center fw-b">
                    合成界面活性剤＋純石けん分のはたらきで、
                    <br className="sp" />
                    日常の汚れや汗・皮脂汚れをしっかり落とします。
                    <br />
                    泡立ち、泡切れが良く、衣類に成分を残しません。
                  </p>
                </div>
              </div>
            </FadeIn.Up>

            <FadeIn.Up>
              <div className="detergentPage__detergentDetail">
                <ContnetsTitle title="Detergent" subTitle="洗剤" />
                <div className="detergentPage__detailTop">
                  <p className="detergentPage__lead fw-b tx-center">
                    peu
                    Detergentは皮膚刺激が少ないとされる非イオン系の界面活性剤をベースに、
                    <br className="pc" />
                    天然由来の純石けん分を配合した、肌にやさしいハイブリッド洗剤です。
                    <br />
                    非イオン系界面活性剤＋純石けん分のはたらきで、少ない洗剤量でも日常の軽い汚れ、汗汚れはもちろん、
                    <br className="pc" />
                    皮脂や油汚れまですっきりと洗いあげます。
                    <span className="fw-n">
                      ＊使用濃度を調整することで、洗濯時排水がもたらす環境への影響に配慮しています。
                    </span>
                  </p>

                  <p className="detergentPage__lead--under fw-b tx-center">
                    においの原因となる汚れをしっかり落とすから、
                    <br className="pc" />
                    香りでにおいをごまかす必要がありません。香料は不使用です。
                  </p>
                </div>
                <div className="detergentPage__detailTable">
                  <div className="detergentPage__detailTableContents">
                    <div className="detergentPage__leftContents">
                      <p className="e en-title fw-n">Ingredients</p>
                      <p className="jp jp-sub-title fw-b">洗剤成分</p>
                    </div>
                    <div className="detergentPage__rightContents">
                      <dl className="detergentPage__rightContentsItem">
                        <dt className="detergentPage__itemTitle fw-b">
                          合成界面活性剤にポリオキシエチレンアルキルエーテル（AE）を使用
                        </dt>
                        <dd className="detergentPage__itemText pc">
                          ・皮膚刺激が少ないとされる非イオン系の界面活性剤
                        </dd>
                        <dd className="detergentPage__itemText pc">
                          ・汚れと水によくなじむ
                        </dd>
                        <dd className="detergentPage__itemText sp">
                          <ul>
                            <li>
                              皮膚刺激が少ないとされる非イオン系の界面活性剤
                            </li>
                            <li>汚れと水によくなじむ</li>
                          </ul>
                        </dd>
                      </dl>
                      <dl className="detergentPage__rightContentsItem">
                        <dt className="detergentPage__itemTitle fw-b">
                          界面活性剤に脂肪酸ナトリウム（純石けん分）を使用
                        </dt>
                        <dd className="detergentPage__itemText pc">
                          ・脂肪酸と水酸化ナトリウムでつくられるシンプルな石けんであるため、肌にやさしい
                        </dd>
                        <dd className="detergentPage__itemText pc">
                          ・石けんの液性(弱アルカリ性)が汚れに対し、優れた洗浄効果を発揮
                        </dd>
                        <dd className="detergentPage__itemText pc">
                          ・石けんならではの泡立ちの良さで汚れを包み込む
                        </dd>
                        <dd className="detergentPage__itemText pc">
                          ・生分解性に優れている
                        </dd>
                        <dd className="detergentPage__itemText sp">
                          <ul>
                            <li>
                              <p>
                                脂肪酸と水酸化ナトリウムでつくられるシンプルな石けんであるため、肌にやさしい
                              </p>
                            </li>
                            <li>
                              <p>
                                石けんの液性(弱アルカリ性)が汚れに対し、優れた洗浄効果を発揮
                              </p>
                            </li>
                            <li>
                              <p>
                                石けんならではの泡立ちの良さで汚れを包み込む
                              </p>
                            </li>
                            <li>
                              <p>生分解性に優れている</p>
                            </li>
                          </ul>
                        </dd>
                      </dl>
                      <dl className="detergentPage__rightContentsItem">
                        <dt className="detergentPage__itemTitle fw-b">
                          着色料／香料不使用
                        </dt>
                      </dl>
                    </div>
                  </div>
                </div>
              </div>
            </FadeIn.Up>

            <FadeIn.Up>
              <div className="detergentPage__softenerDetail">
                <ContnetsTitle title="Softener" subTitle="柔軟剤" />

                <div className="detergentPage__detailTop tx-center">
                  <p className="detergentPage__lead fw-b">
                    peu Softenerは生分解性に優れた植物原料の陽イオン系
                    界面活性剤をベースとしています。
                    <br />
                    界面活性剤の持つ柔軟効果で衣類の風合いはそのまま、柔らかく、滑らかに仕上げます。
                    <br />
                    繊維の吸水性を阻害しないため、洗濯後の衣類は水分もしっかり吸収します。
                    <br />
                    洗濯乾燥後の衣類のまっさらな状態をそのまま味わっていただくため、香料は使用していません。
                  </p>
                </div>
                <div className="detergentPage__detailTable">
                  <div className="detergentPage__detailTableContents">
                    <div className="detergentPage__leftContents">
                      <p className="e en-title fw-n">Ingredients</p>
                      <p className="jp jp-sub-title fw-b">洗剤成分</p>
                    </div>
                    <div className="detergentPage__rightContents">
                      <dl className="detergentPage__rightContentsItem">
                        <dt className="detergentPage__itemTitle fw-b">
                          合成界面活性剤にエステル型ジアルキルアンモニウム塩を使用
                        </dt>
                        <dd className="detergentPage__itemText pc">
                          ・植物原料の合成界面活性剤がベース
                        </dd>
                        <dd className="detergentPage__itemText pc">
                          ・界面活性剤の持つ柔軟効果により、衣類が柔らかく、肌触りよく仕上がる
                        </dd>
                        <dd className="detergentPage__itemText pc">
                          ・潤滑性、静電気防止効果に優れている
                        </dd>
                        <dd className="detergentPage__itemText pc">
                          ・構造上、他の合成界面活性剤と比較し生分解性に優れている
                        </dd>
                        <dd className="detergentPage__itemText sp">
                          <ul>
                            <li>
                              <p>植物原料の合成界面活性剤がベース</p>
                            </li>
                            <li>
                              <p>
                                界面活性剤の持つ柔軟効果により、衣類が柔らかく、肌触りよく仕上がる
                              </p>
                            </li>
                            <li>
                              <p>潤滑性、静電気防止効果に優れている</p>
                            </li>
                            <li>
                              <p>
                                構造上、他の合成界面活性剤と比較し生分解性に優れている
                              </p>
                            </li>
                          </ul>
                        </dd>
                      </dl>
                      <dl className="detergentPage__rightContentsItem">
                        <dt className="detergentPage__itemTitle fw-b">
                          着色料／香料不使用
                        </dt>
                      </dl>
                    </div>
                  </div>

                  <div className="detergentPage__detailTableContents">
                    <div className="detergentPage__leftContents">
                      <p className="e en-title fw-n">Finish Condition</p>
                      <p className="jp jp-sub-title fw-b">仕上がり</p>
                    </div>
                    <div className="detergentPage__rightContents">
                      <dl className="detergentPage__rightContentsItem">
                        <dd className="detergentPage__itemText">
                          peu
                          Softenerを使用すると繊維と繊維が絡まりにくくなり、弾力が生まれ、洗濯物がふかふかに仕上がります。
                        </dd>
                      </dl>
                    </div>
                  </div>
                </div>

                <div className="detergentPage__note">
                  <p className="detergentPage__noteText">
                    peuは2023年1月に成分をリニューアルしました。
                    <br />
                    一部の店舗ではリニューアル前の洗剤を使用しており、順次切り替えを進めています。
                    <br className="pc" />
                    なお、リニューアル前のpeu
                    柔軟剤には、天然香料を使用しています。
                  </p>
                  <p className="detergentPage__noteText detergentPage__noteText--mt">
                    お使いの店舗の洗剤は店舗に掲示したポスターでご確認いただけます。
                    <br />
                    このロゴのポスターを掲示している店舗ではリニューアルしたpeuを使用しています。
                  </p>
                  <img
                    src="https://balukoweb.blob.core.windows.net/images/self-laundry/detergent/peu-logo.png"
                    className="detergentPage__peuIcon"
                    alt="peuIcon"
                  />
                </div>
              </div>
            </FadeIn.Up>
          </div>
        </div>
      </section>
    </FadeIn.Up>
  )
}
export default Product
