import React from "react"
import * as FadeIn from "components/fadein"

const AboutSomali = () => {
  return (
    <FadeIn.Up>
      <section className="detergentPage__aboutSomali">
        <div className="detergentPage__containerWrap">
          <FadeIn.Up>
            <div className="detergentPage__container">
              <div className="detergentPage__titleImageWrap">
                <img
                  src="https://balukoweb.blob.core.windows.net/images/self-laundry/detergent/somali-logo.png"
                  className="detergentPage__somaliTitleLoog"
                  alt="somaliLogo"
                />
              </div>

              <p className="detergentPage__lead tx-center fw-b">
                バルコランドリープレイスではナチュラルコースにSOMALIを使用しています。
                <br />
                ［SOMALI（そまり）］は大正13年創業の老舗石鹸メーカー木村石鹸による
                <br className="pc" />
                天然素材の優しさと石けん職人のこだわりが詰まったハウスケア&ボディケアブランドです。
                <br />
                昔ながらの窯炊き製法によって、長い時間をかけてうまれる［SOMALI（そまり）］。
                <br className="pc" />
                石けんならではの泡立ち·泡切れの良さで、素材を傷めず洗い、洗濯物を優しく柔らかに仕上げます。
                <br />
                柔軟剤のかわりに使用するのは［SOMALI（そまり）］衣類のリンス剤。
                <br className="pc" />
                クエン酸が洗剤の繊維残りを除去し、繊維を蘇らせます。
                <br />
                小さなお子様や肌の弱い方、どなたにも安心してご利用いただけます。
              </p>
              <div className="detergentPage__detail">
                <p className="detergentPage__detailTitle tx-center fw-b">
                  ［SOMALI（そまり）］は5つの不使用をあげています。
                </p>
                <ul className="detergentPage__detailLead fw-b tx-center">
                  <li>
                    <p>石油由来界面活性剤</p>
                  </li>
                  <li>
                    <p>合成香料</p>
                  </li>
                  <li>
                    <p>合成着色料</p>
                  </li>
                  <li>
                    <p>石油由来防腐剤</p>
                  </li>
                  <li>
                    <p>旧表示指定成分</p>
                  </li>
                </ul>
              </div>
              <div className="detergentPage__linkGroup">
                <a
                  href="https://www.kimurasoap.co.jp/somali/"
                  el="noopener noreferrer"
                  className="detergentPage__linkText fw-b"
                >
                  SOMALIブランドサイト
                </a>
                <img
                  src="https://balukoweb.blob.core.windows.net/images/self-laundry/detergent/link-img.png"
                  className="detergentPage__linkIcon"
                  alt="linkIcon"
                />
              </div>
              <p className="detergentPage__underNote">
                ※一部の店舗ではナチュラルコースをご利用いただけません。
              </p>
            </div>
          </FadeIn.Up>
        </div>
      </section>
    </FadeIn.Up>
  )
}
export default AboutSomali
