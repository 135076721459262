import React from "react"
import Layout from "components/layout"
import Seo from "components/seo"
import { Fv } from "components/fv"
import AboutPeu from "components/self-laundry/detergent/aboutPeu"
import Product from "components/self-laundry/detergent/product"
import AboutSomali from "components/self-laundry/detergent/aboutSomali"

const DetergentPage = () => {
  return (
    <Layout>
      <div className="detergentPage design-style">
        <Fv
          pcFv="https://balukoweb.blob.core.windows.net/images/self-laundry/detergent/page-mv.jpg"
          spFv="https://balukoweb.blob.core.windows.net/images/self-laundry/detergent/page-mv-sp.jpg"
          fvTitle="Detergent & Softener"
          color="white"
        />
        <AboutPeu />
        <Product />
        <AboutSomali />
      </div>
    </Layout>
  )
}
export const Head = () => (
  <Seo
    title="Self Laundry － 洗剤・柔軟剤について"
    description="バルコのセルフランドリーで使用している洗剤・柔軟剤についてご紹介します。
  バルコのオリジナル洗剤・柔軟剤peuは「洗う」に必要な成分のみでつくったシンプルな洗剤・柔軟剤。
  スタンダードコース、標準コース、羽毛布団コースなどでご利用いただけます。"
  />
)
export default DetergentPage
